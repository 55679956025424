import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer__container">
      <div className="footer__wrapper">
        <div className="footer__wrapper__col-1">
          <h5>درباره ما</h5>
          <p>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
            و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
            زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و
            متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان
            رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد
            کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه
            راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
            حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
            طراحی اساسا مورد استفاده قرار گیرد.
          </p>
        </div>
        <div className="footer__wrapper__col-2">
            <h5>آخرین مطالب</h5>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
        </div>
        <div className="footer__wrapper__col-3">
            <h5>دسترسی سریع</h5>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی</p>
        </div>
        <div className="footer__wrapper__col-4">
            <p>لورم ایپسوم متن ساختگی با تولید سادگی</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی</p>
            <p>لورم ایپسوم متن ساختگی با تولید سادگی</p>
        </div>
      </div>
      <div className="footer__copyright">
        <p>کلیه حقوق برای آکادمی آموزش برنامه نویسی سبزلرن محفوظ است</p>
      </div>
    </div>
  );
}
